<template>
    <div class="relative w-full aspect-[3/2] overflow-hidden rounded-2xl not-select" ref="container">
        <component :is="afterMediaType" :src="afterMediaSrc" class="absolute top-0 left-0 w-full h-full object-cover"
            ref="afterMedia" @dragstart="event => event.preventDefault()" autoplay loop muted playsinline
            @loadeddata="onAfterMediaLoaded" />
        <component :is="beforeMediaType" :src="beforeMediaSrc" class="absolute top-0 left-0 w-full h-full object-cover"
            ref="beforeMedia" @dragstart="event => event.preventDefault()" autoplay loop muted playsinline
            @loadeddata="onBeforeMediaLoaded" />
        <div class="absolute bg-white h-full w-1 cursor-ew-resize" :style="{ left: sliderLeft }" ref="slider"
            @mousedown="startDragging" @touchstart="startDragging">
            <div
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-6 h-6 bg-white rounded-full shadow-lg">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
    beforeMediaSrc: {
        type: String,
        required: true
    },
    afterMediaSrc: {
        type: String,
        required: true
    }
});

const beforeMediaType = computed(() => props.beforeMediaSrc.endsWith('.webm') ? 'video' : 'img');
const afterMediaType = computed(() => props.afterMediaSrc.endsWith('.webm') ? 'video' : 'img');

const container = ref<HTMLDivElement | null>(null);
const slider = ref<HTMLDivElement | null>(null);
const beforeMedia = ref<HTMLVideoElement | HTMLImageElement | null>(null);
const afterMedia = ref<HTMLVideoElement | HTMLImageElement | null>(null);
let isDragging = ref(false);
const sliderLeft = ref('50%');

const beforeMediaLoaded = ref(false);
const afterMediaLoaded = ref(false);

const slide = (xPosition: number) => {
    if (!container.value || !beforeMedia.value || !slider.value) return;

    const containerRect = container.value.getBoundingClientRect();
    const sliderPosition = Math.max(0, Math.min(xPosition - containerRect.left, containerRect.width));
    const sliderPercent = (sliderPosition / containerRect.width) * 100;

    beforeMedia.value.style.clipPath = `inset(0 ${100 - sliderPercent}% 0 0)`;
    sliderLeft.value = `${sliderPercent}%`;
};

const handleMove = (e: MouseEvent | TouchEvent) => {
    if (!isDragging.value) return;

    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    slide(clientX);
};

const startDragging = () => {
    isDragging.value = true;
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('touchmove', handleMove);
};

const stopDragging = () => {
    isDragging.value = false;
    window.removeEventListener('mousemove', handleMove);
    window.removeEventListener('touchmove', handleMove);
};

const onBeforeMediaLoaded = () => {
    beforeMediaLoaded.value = true;
    checkAndSyncPlayback();
};

const onAfterMediaLoaded = () => {
    afterMediaLoaded.value = true;
    checkAndSyncPlayback();
};

const checkAndSyncPlayback = () => {
    if (beforeMediaLoaded.value && afterMediaLoaded.value) {
        const beforeVideo = beforeMedia.value as HTMLVideoElement | null;
        const afterVideo = afterMedia.value as HTMLVideoElement | null;

        if (beforeVideo && afterVideo) {
            beforeVideo.currentTime = afterVideo.currentTime;

            Promise.all([beforeVideo.play(), afterVideo.play()])
                .then(() => {
                    console.log('Both videos are playing');
                })
                .catch((error) => {
                    console.error('Error playing videos:', error);
                });
        } else {
            console.error('One of the video elements is not available.');
        }
    }
};

onMounted(() => {
    window.addEventListener('mouseup', stopDragging);
    window.addEventListener('touchend', stopDragging);
    if (container.value) {
        const containerRect = container.value.getBoundingClientRect();
        const containerCenter = containerRect.left + containerRect.width / 2;
        slide(containerCenter);
    }
});

onBeforeUnmount(() => {
    window.removeEventListener('mouseup', stopDragging);
    window.removeEventListener('touchend', stopDragging);
});
</script>

<style scoped>
.not-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>